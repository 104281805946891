import { resizeImage, ResizeOption } from "@/utils/cloudinary";
import { Image, PropsOf } from "@chakra-ui/react";

type Props = PropsOf<typeof Image> & {
  resize?: ResizeOption;
};

const DefaultFallback =
  "https://res.cloudinary.com/dkk8i0jaj/image/upload/v1673970407/imjanggogo/default/imjang_c0ckc6.png";

const CustomImage = ({ src, resize, fallbackSrc, ...props }: Props) => {
  const fallback = resizeImage(fallbackSrc || DefaultFallback, resize);

  return (
    <Image
      src={resizeImage(src, resize) || fallback}
      fallbackStrategy="onError"
      fallbackSrc={fallback}
      {...props}
      alt={props.alt}
    />
  );
};

export default CustomImage;
