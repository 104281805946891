import { HOST } from "@/constants/config";
import axios from "axios";

export enum ImageUploadPreset {
  Imjanggogo = "imjanggogo",
}

type TImageResponse = {
  url: string;
  public_id: string;
  secure_url: string;
  original_filename: string;
  format: string;
};

export const uploadImages = async (
  images: File[],
  preset: ImageUploadPreset
) => {
  const formData = new FormData();
  const uploadedResult: TImageResponse[] = [];

  if (!process.env.NEXT_PUBLIC_CLOUDINARY_KEY) {
    throw new Error("clodinary key 가 env에 존재하지 않아요");
  }

  for (const image of images) {
    formData.append("file", image);
    formData.append("upload_preset", preset);
    formData.append("api_key", process.env.NEXT_PUBLIC_CLOUDINARY_KEY);

    try {
      const { data } = await axios.post<TImageResponse>(
        `https://api.cloudinary.com/v1_1/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload`,
        formData
      );

      uploadedResult.push(data);
    } catch (e) {
      console.error(e);
    }
  }

  return uploadedResult;
};

const uploadPath = `${HOST.cloudinary}/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload`;
const isUploadedImage = (src: string) => src.startsWith(uploadPath);

export type ResizeOption = { width?: number; height?: number; crop?: "fill" };

export const resizeImage = (src: string, option?: ResizeOption) => {
  if (!src || !isUploadedImage(src)) {
    return src;
  }

  const [, mainPath] = src.split(uploadPath);
  const { width = 1000, height = 1000, crop = "fill" } = option || {};
  const modes = [
    width && `w_${width}`,
    height && `h_${height}`,
    crop && `c_${crop}`,
  ]
    .filter(Boolean)
    .join(",");

  return `${uploadPath}/${modes}/${mainPath}`;
};
