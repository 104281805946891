import { MainBodyDto } from "@/apis/api";
import ImjangList, { MainTemplateType } from "@/components/Imjang/ImjangList";
import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  data: MainBodyDto[];
};

const MainList = ({ data }: Props) => {
  return (
    <Box p={5}>
      <Stack spacing={5}>
        {data.map((item, i) => (
          <React.Fragment key={i}>
            <Text textStyle="h1" color="text">
              {item.templateTitle}
            </Text>
            <ImjangList
              type={item.templateType as MainTemplateType}
              items={item.items}
            />
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};
export default MainList;
