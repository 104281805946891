import { format } from "date-fns";

export const DateFormat = {
  yyyymmdd: "yyyy. MM. dd.",
  yyyy_mm_dd: "yyyy-MM-dd",
  yyyymmdd_24H: "yyyy. MM. dd. HH:mm",
  mo: "Mo",
  mmmm: "MMMM",
};

export const parseDate = (date: Date | string): Date => {
  if (typeof date === "string") {
    return new Date(date);
  }

  return date;
};

export const formatDate = (
  date: Date | string,
  dateFormat = DateFormat.yyyymmdd,
  options?: Parameters<typeof format>[2]
) => {
  return format(parseDate(date), dateFormat, options);
};
