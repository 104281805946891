import ImageOverlay from "@/components/ImageOverlay";
import { Avatar, Box, HStack, Stack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FiSearch } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { MainHeaderDto } from "@/apis/api";

import "swiper/css";
import "swiper/css/pagination";
import CustomImage from "@/components/CustomImage";
import { appScheme } from "@/constants/appScheme";

type Props = {
  data: MainHeaderDto[];
};

const MainHeader = ({ data }: Props) => {
  const { push } = useRouter();

  const handleItemClick = (item: MainHeaderDto) => {
    if (item.postUrl) {
      window.dataLayer?.push({
        event: "main_header_click",
        post_url: item.postUrl,
      });

      window.open(appScheme.webview(item.postUrl, { appbarTitle: item.title }));
    } else if (item.imjangId) {
      push(`/imjang/${item.imjangId}`);
    }
  };

  return (
    <Box position="relative">
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
      >
        {data.map((item, i) => (
          <SwiperSlide key={i} onClick={() => handleItemClick(item)}>
            <Box position="relative">
              <CustomImage
                alt={item.title || ""}
                src={item.imageUrl || ""}
                width="100%"
                height="400px"
                objectFit="cover"
              />
              <ImageOverlay>
                <Stack p={5} color="white">
                  <Text fontSize="2xl" fontWeight="bold">
                    {item.title}
                  </Text>
                  {item.userNickName && (
                    <HStack>
                      <Avatar size="xs" src={item.userProfileImageUrl} />
                      <Text>{item.userNickName}</Text>
                    </HStack>
                  )}
                </Stack>
              </ImageOverlay>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <HStack
        as="button"
        position="absolute"
        width="90%"
        top={5}
        left="5%"
        right="5%"
        zIndex="overlay"
        background="white"
        borderRadius="full"
        boxShadow="dark-lg"
        mt="safeAreaTop"
        px={5}
        py={3}
        spacing={4}
        name="main_search"
        onClick={() => push("/search")}
      >
        <FiSearch />
        <Text textAlign="center">아파트 명을 검색하세요</Text>
      </HStack>
    </Box>
  );
};

export default MainHeader;
