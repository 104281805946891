import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const ImageOverlay = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Box
      position="absolute"
      left={0}
      bottom={0}
      right={0}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgGradient: "linear(to-t, rgba(0, 0, 0, 0.7), transparent)",
      }}
    >
      <Box position="relative">{children}</Box>
    </Box>
  );
};

export default ImageOverlay;
