import { MainBodyItem } from "@/apis/api";
import CustomImage from "@/components/CustomImage";
import ImageOverlay from "@/components/ImageOverlay";
import Link from "@/components/Link";
import { DateFormat, formatDate } from "@/utils/date";
import {
  AspectRatio,
  Avatar,
  Box,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";

type CommonProps = {
  items: MainBodyItem[];
  hideWriter?: boolean;
};

const ImjangType = ({ items, hideWriter }: CommonProps) => {
  return (
    <SimpleGrid columns={2} spacing={3}>
      {items.map((item, i) => (
        <Link key={i} href={`/imjang/${item.imjangId}`} textDecoration="none">
          <Stack>
            <Box position="relative" borderRadius={10} overflow="hidden">
              <AspectRatio>
                <CustomImage
                  alt={item.title || ""}
                  src={item.imageUrl || ""}
                  resize={{
                    width: 300,
                    height: 300,
                  }}
                />
              </AspectRatio>
              {!hideWriter && item.userNickName && (
                <ImageOverlay>
                  <HStack color="white" p={2}>
                    <Avatar size="xs" src={item.userProfileImageUrl} />
                    <Text textStyle="c1">{item.userNickName}</Text>
                  </HStack>
                </ImageOverlay>
              )}
            </Box>
            <Stack spacing={1}>
              <Text textStyle="b1" noOfLines={1}>
                {item.title}
              </Text>
              {item.imjangDateTime && (
                <Text textAlign="right" textStyle="c2" color="GrayText">
                  {formatDate(item.imjangDateTime, DateFormat.yyyymmdd)}
                </Text>
              )}
            </Stack>
          </Stack>
        </Link>
      ))}
    </SimpleGrid>
  );
};

const PostType = ({ items, hideWriter }: CommonProps) => {
  const handleClickPost = (item: MainBodyItem) => {
    window.dataLayer?.push({
      event: "main_post_click",
      post_url: item.url,
    });
  };

  return (
    <Stack spacing={5} divider={<StackDivider />}>
      {items.map((item, i) => (
        <Link
          key={i}
          href={item.url || ""}
          textDecoration="none"
          onClick={() => handleClickPost(item)}
        >
          <Stack spacing={3}>
            <HStack textStyle="c2">
              {!hideWriter && item.userNickName && (
                <>
                  <Avatar size="sm" src={item.userProfileImageUrl} />
                  <Text textStyle="c1">{item.userNickName}</Text>
                </>
              )}
              <Spacer />
              {item.imjangDateTime && (
                <Text textAlign="right" color="GrayText">
                  {formatDate(item.imjangDateTime, DateFormat.yyyymmdd)}
                </Text>
              )}
            </HStack>
            <Box position="relative" borderRadius={10} overflow="hidden">
              <AspectRatio ratio={16 / 9}>
                <CustomImage
                  alt={item.title || ""}
                  src={item.imageUrl || ""}
                  resize={{
                    width: 500,
                    height: 300,
                  }}
                />
              </AspectRatio>
            </Box>
            <Text textStyle="h2" noOfLines={1}>
              {item.title}
            </Text>
          </Stack>
        </Link>
      ))}
    </Stack>
  );
};

export type MainTemplateType = "imjang" | "post";

type Props = {
  type: MainTemplateType;
} & CommonProps;

const ListComponent = {
  imjang: ImjangType,
  post: PostType,
};

const ImjangList = ({ type, hideWriter, items }: Props) => {
  const Comp = ListComponent[type];

  return <Comp hideWriter={hideWriter} items={items} />;
};

export default ImjangList;
