import Layout from "@/components/Layout";

import "swiper/css";
import "swiper/css/pagination";
import { ServerSidePropsWithQuery } from "@/types/reactQuery";
import { dehydrate, QueryClient, useQuery } from "@tanstack/react-query";
import { mainPageQuery } from "@/query/main";
import MainHeader from "@/components/Main/MainHeader";
import MainList from "@/components/Main/MainList";
import SectionRenderer from "@/components/SectionRenderer";

export const getServerSideProps: ServerSidePropsWithQuery = async ({ req }) => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(
    mainPageQuery.query({
      headers: { cookie: req.headers.cookie },
    })
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default function Home() {
  const { data, isLoading, error } = useQuery(mainPageQuery.query());

  return (
    <Layout header={false} bottomNavigation px={0} pt={0}>
      <SectionRenderer loading={isLoading} error={error}>
        {data?.result && (
          <>
            {data.result.headers && <MainHeader data={data.result.headers} />}
            {data.result.bodies && <MainList data={data.result.bodies} />}
          </>
        )}
      </SectionRenderer>
    </Layout>
  );
}
